export default [
  {
    path: '/abi/es',
    name: 'ABI-ES',
    component: () => import('@/views/ABI/EntrySummary/EntrySummary.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Entry Summary', active: true },
      ],
      pageTitle: 'Entry Summary',
      resource: 'ABI-ES',
      action: 'read',
    },
  },
  {
    path: '/abi/es/detail/:esId',
    name: 'ABI-ESDetail',
    component: () => import('@/views/ABI/EntrySummary/ESDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Entry Summary', to: '/abi/es' },
        { text: 'Summary Detail', active: true },
      ],
      navActiveLink: 'ABI-ES',
      pageTitle: 'Summary Detail',
      resource: 'ABI-ES',
      action: 'read',
    },
  },
  {
    path: '/abi/es/detail/:esId/line/:lineid',
    name: 'ABI-ESLineDetail',
    component: () => import('@/views/ABI/EntrySummary/EntrySummaryLineDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Entry Summary', to: '/abi/es' },
        { text: 'Summary Detail' },
        { text: 'Line Detail', active: true },
      ],
      navActiveLink: 'ABI-ES',
      pageTitle: 'Line Detail',
      back: true,
      resource: 'ABI-ES',
      action: 'read',
    },
  },
  {
    path: '/abi/isf',
    name: 'ABI-ISF',
    component: () => import('@/views/ABI/ISF/ISF.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'ISF', active: true },
      ],
      pageTitle: 'ISF',
      resource: 'ABI-ISF',
      action: 'read',
    },
  },
  {
    path: '/abi/isf/detail/:isfId',
    name: 'ABI-ISFDetail',
    component: () => import('@/views/ABI/ISF/ISFDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'ISF', to: '/abi/isf' },
        { text: 'ISF Detail', active: true },
      ],
      navActiveLink: 'ABI-ISF',
      pageTitle: 'ISF Detail',
      resource: 'ABI-ES',
      action: 'read',
    },
  },
  {
    path: '/abi/customers',
    name: 'ABI-Customers',
    component: () => import('@/views/ABI/Customers/Customers.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Customers', active: true },
      ],
      pageTitle: 'Customers',
      resource: 'ABI-Party',
      action: 'read',
    },
  },
  {
    path: '/abi/parties',
    name: 'ABI-Parties',
    component: () => import('@/views/ABI/Parties/Parties.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Parties', active: true },
      ],
      pageTitle: 'Parties',
      resource: 'ABI-Party',
      action: 'read',
    },
  },
  {
    path: '/abi/parties/detail/:partyId',
    name: 'ABI-PartyDetail',
    component: () => import('@/views/ABI/Parties/PartyDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Parties', to: '/abi/parties' },
        { text: 'Party Detail', active: true },
      ],
      navActiveLink: 'ABI-Parties',
      pageTitle: 'Party Detail',
      resource: 'ABI-Party',
      action: 'read',
    },
  },
  {
    path: '/abi/hts',
    name: 'ABI-HTS',
    component: () => import('@/views/ABI/HTS/HTS.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'HTS Database', active: true },
      ],
      pageTitle: 'HTS',
      resource: 'ABI-ES',
      action: 'read',
    },
  },
  {
    path: '/abi/query/adcvd',
    name: 'ABI-Query-ADCVD',
    component: () => import('@/views/ABI/Query/ADCVD.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'AD CVD', active: true },
      ],
      pageTitle: 'AD CVD Case',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/adcvd/detail/:casenumber',
    name: 'ABI-Query-ADCVDDetail',
    component: () => import('@/views/ABI/Query/ADCVDDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'AD CVD', to: '/abi/query/adcvd' },
        { text: 'AD CVD Detail', active: true },
      ],
      navActiveLink: 'ABI-Query-ADCVD',
      pageTitle: 'AD CVD Detail',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/cargostatus',
    name: 'ABI-Query-CargoStatus',
    component: () => import('@/views/ABI/Query/CargoStatus.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Cargo Status', active: true },
      ],
      pageTitle: 'Cargo Status',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/cargostatus/detail/:queryid/:type/:master/:subscriptionstatus',
    name: 'ABI-Query-CargoStatusDetail',
    component: () => import('@/views/ABI/Query/CargoStatusDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Cargo Status', to: '/abi/query/cargostatus' },
        { text: 'Cargo Status Detail', active: true },
      ],
      navActiveLink: 'ABI-Query-CargoStatus',
      pageTitle: 'Cargo Status Detail',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/importer',
    name: 'ABI-Query-Importer',
    component: () => import('@/views/ABI/Query/Importer.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Importer/Bond', active: true },
      ],
      pageTitle: 'Importer/Bond',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/importer/detail/:importerid',
    name: 'ABI-Query-ImporterDetail',
    component: () => import('@/views/ABI/Query/ImporterDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Importer/Bond', to: '/abi/query/importer' },
        { text: 'Importer/Bon Detail', active: true },
      ],
      navActiveLink: 'ABI-Query-Importer',
      pageTitle: 'Importer/Bond Detail',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/manufacturer',
    name: 'ABI-Query-MID',
    component: () => import('@/views/ABI/Query/MID.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'MID Query', active: true },
      ],
      pageTitle: 'MID Query',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/manufacturer/detail/:mid',
    name: 'ABI-Query-MIDDetail',
    component: () => import('@/views/ABI/Query/MIDDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'MID Query', to: '/abi/query/manufacturer' },
        { text: 'MID Detail', active: true },
      ],
      navActiveLink: 'ABI-Query-MID',
      pageTitle: 'MID Detail',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/query/es',
    name: 'ABI-Query-ES',
    component: () => import('@/views/ABI/Query/ES.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Entry Summary', active: true },
      ],
      pageTitle: 'Entry Summary',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/statement/daily',
    name: 'ABI-Statement-Daily',
    component: () => import('@/views/ABI/Statement/DailyStatement.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Daily Statement', active: true },
      ],
      pageTitle: 'Daily Statement',
      resource: 'ABI-Statement',
      action: 'read',
    },
  },
  {
    path: '/abi/statement/daily/detail/:statementid',
    name: 'ABI-Statement-DailyDetail',
    component: () => import('@/views/ABI/Statement/DailyStatementDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Daily Statement', to: '/abi/statement/daily' },
        { text: 'Daily Statement Detail', active: true },
      ],
      navActiveLink: 'ABI-Statement-Daily',
      pageTitle: 'Daily Statement Detail',
      resource: 'ABI-Statement',
      action: 'read',
    },
  },
  {
    path: '/abi/statement/monthly',
    name: 'ABI-Statement-Monthly',
    component: () => import('@/views/ABI/Statement/MonthlyStatement.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Monthly Statement', active: true },
      ],
      pageTitle: 'Monthly Statement',
      resource: 'ABI-Statement',
      action: 'read',
    },
  },
  {
    path: '/abi/statement/monthly/detail/:statementid',
    name: 'ABI-Statement-MonthlyDetail',
    component: () => import('@/views/ABI/Statement/MonthlyStatementDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Monthly Statement', to: '/abi/statement/monthly' },
        { text: 'Monthly Statement Detail', active: true },
      ],
      navActiveLink: 'ABI-Statement-Monthly',
      pageTitle: 'Monthly Statement Detail',
      resource: 'ABI-Statement',
      action: 'read',
    },
  },
  // Reference Extract
  {
    path: '/abi/extract/carrier',
    name: 'ABI-Extract-Carriers',
    component: () => import('@/views/ABI/ExtractReference/CarrierReference.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Carriers Reference', active: true },
      ],
      pageTitle: 'Carriers',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/extract/country',
    name: 'ABI-Extract-Country',
    component: () => import('@/views/ABI/ExtractReference/CountryReference.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Countries Reference', active: true },
      ],
      pageTitle: 'Countries Reference',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/extract/foreignport',
    name: 'ABI-Extract-ForeignPort',
    component: () => import('@/views/ABI/ExtractReference/ForeignPortsReference.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Foreign Ports Reference', active: true },
      ],
      pageTitle: 'Foreign Ports Reference',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/extract/firms',
    name: 'ABI-Extract-FIRMS',
    component: () => import('@/views/ABI/ExtractReference/FIRMSReference.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'FIRMS Reference', active: true },
      ],
      pageTitle: 'FIRMS Reference',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  {
    path: '/abi/extract/port',
    name: 'ABI-Extract-Port',
    component: () => import('@/views/ABI/ExtractReference/RegionDistrictPortReference.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Region/District/Port Reference', active: true },
      ],
      pageTitle: 'Region/District/Port Reference',
      resource: 'ABI-Query',
      action: 'read',
    },
  },
  // Settings
  {
    path: '/abi/settings/queue',
    name: 'ABI-Settings-Queue',
    component: () => import('@/views/ABI/Settings/QueueSetup.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Queue Setup', active: true },
      ],
      pageTitle: 'Queue Setup',
      resource: 'ABI-Settings',
      action: 'read',
    },
  },
  {
    path: '/abi/settings/modules',
    name: 'ABI-settings-Modules',
    component: () => import('@/views/ABI/Settings/ModuleSetup.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Modules', active: true },
      ],
      pageTitle: 'Modules',
      resource: 'ABI-Settings',
      action: 'read',
    },
  },
  // {
  //   path: '/abi/settings/users',
  //   name: 'ABI-settings-Users',
  //   component: () => import('@/views/ABI/Settings/UserSetup.vue'),
  //   meta: {
  //     breadcrumb: [
  //       // { text: 'Home', to: '/dashboard' },
  //       { text: 'User Modules', active: true },
  //     ],
  //     pageTitle: 'User Modules',
  //     resource: 'ABI-Settings',
  //     action: 'read',
  //   },
  // },
]
