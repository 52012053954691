import Vue from 'vue'

export default {}
Vue.mixin({
  methods: {
    CheckNull(object) { // If not null - return true
      return object && object !== 'null' && object !== 'undefined'
    },
    CheckNullEmpty(object) { // If not null - return true
      return object && object !== 'null' && object !== 'undefined' && object !== ''
    },
  },
})
