export default [
  {
    path: '/user/myaccount',
    name: 'User-Account',
    component: () => import('@/views/User/MyAccount.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Account Settings', active: true },
      ],
      pageTitle: 'Account Settings',
      resource: 'User',
      action: 'read',
    },
  },
]
