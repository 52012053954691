import Vue from 'vue'

export default {}
Vue.mixin({
  methods: {
    DisplaySuccess(msg) {
      return this.$swal({
        icon: 'success',
        title: 'Success',
        text: msg,
      })
    },
    DisplayError(title, msg = '') {
      return this.$swal({
        icon: 'error',
        title: `Oops..., ${title}`,
        text: msg,
      })
    },
    DisplaySuccessRemove(msg) {
      return this.$swal({
        icon: 'success',
        title: 'Success',
        text: msg,
      })
    },
    DisplayErrorRemove(title, msg = '') {
      return this.$swal({
        icon: 'error',
        title: `Oops..., ${title}`,
        text: msg,
      })
    },
  },
})
