import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import LoadingMixin from '@/mixin/LoadingMixin'
import Mixin from '@/mixin/Mixin'
import PermissionMixin from '@/mixin/PermissionMixin'
import NotificationMixin from '@/mixin/NotificationMixin'
import AgGridMixin from '@/mixin/AgGridMixin'
// Icons
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
// ACL
import '@/libs/acl'
import '@/libs/ag-grid'
import '@/libs/Directive'
import '@/libs/portal-vue'
import '@/libs/multiselect'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/vue-pipeline'
import '@/libs/loading'

library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Minxin

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css')
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// const loader = Vue.$loading.show()
Vue.prototype.$http.defaults.headers.common = { 'api-key': store.state.user.AppActiveUser.Id, UserId: store.state.user.AppActiveUser.Id }

Vue.mixin([LoadingMixin, PermissionMixin, NotificationMixin, Mixin, AgGridMixin])

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
