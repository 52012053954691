import Vue from 'vue'

export default {}

Vue.prototype.$filterParams = {
  // provide comparator function
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue

    if (dateAsString == null) {
      return -1
    }

    // In the example application, dates are stored as mm/dd/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('/')
    const day = Number(dateParts[1])
    const month = Number(dateParts[0]) - 1
    const year = Number(dateParts[2])
    const cellDate = new Date(year, month, day)

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    } if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  },
}

Vue.mixin({
  methods: {
    InitGridInstance(name) {
      return {
        Name: name,
        gridOptions: {
          rowGroupPanelShow: 'always',
          paginationPageSize: 30,
          pagination: true,
          multiSortKey: 'ctrl',
          suppressDragLeaveHidesColumns: true,
          suppressMakeColumnVisibleAfterUnGroup: true,
          suppressMenuHide: true,
          onRowDoubleClicked: this.DoubleClickOpenPopup,
          onSortChanged: null,
          onColumnResized: null,
          onColumnMoved: null,
        },
        defaultColDef: {
          // make every column use 'text' filter by default
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          enablePivot: true,
        },
        columnDefs: null,
        rowData: null,
        context: { componentParent: this },
        frameworkComponents: null,
        detailCellRendererParams: null,
        detailRowAutoHeight: true,
        masterDetail: false,
      }
    },
    dateComparator(date1, date2) {
      const date1Number = this.monthToNum(date1)
      const date2Number = this.monthToNum(date2)

      if (date1Number === null && date2Number === null) {
        return 0
      }
      if (date1Number === null) {
        return -1
      }
      if (date2Number === null) {
        return 1
      }

      return date1Number - date2Number
    },
    monthToNum(date) {
      if (date === undefined || date === null) {
        return null
      }

      let result = 0
      try {
        result = new Date(date).getTime()
      } catch (e) {
        result = 0
      }

      // 0  3  6    12   14
      // mm/dd/yyyy HH:mm
      // const yearNumber = date.substring(6, 10)
      // const dayNumber = date.substring(3, 5)
      // const monthNumber = date.substring(0, 2)

      // let hourNumber = 0
      // let minuteNumber = 0
      // try {
      //   hourNumber = date.substring(11, 13)
      // } catch (e) { hourNumber = 0 }
      // try {
      //   minuteNumber = date.substring(14, 16)
      // } catch (e) { minuteNumber = 0 }

      // const result = yearNumber * 10000 + monthNumber * 100 + dayNumber * 10 + hourNumber * 5 + minuteNumber * 1
      // // 29/08/2004 => 20040829
      return result
    },
  },
})
