export default [
  {
    path: '/admin/dashboard',
    name: 'Admin-Dashboard',
    component: () => import('@/views/Admin/Dashboard.vue'),
    meta: {
      breadcrumb: [
        { text: 'Admin Home', to: '/admin/dashboard', active: true },
      ],
      pageTitle: 'Admin Dashboard',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/admin/userlist',
    name: 'Admin-UserList',
    component: () => import('@/views/Admin/UserList.vue'),
    meta: {
      breadcrumb: [
        { text: 'Admin Home', to: '/admin/dashboard' },
        { text: 'User List', active: true },
      ],
      pageTitle: 'User List',
      resource: 'Admin-User',
      action: 'read',
    },
  },
  {
    path: '/admin/userlist/detail/:userid',
    name: 'Admin-UserListDetail',
    component: () => import('@/views/Admin/UserDetail.vue'),
    meta: {
      breadcrumb: [
        { text: 'Admin Home', to: '/admin/dashboard' },
        { text: 'User List', to: '/admin/userlist' },
        { text: 'Detail', active: true },
      ],
      pageTitle: 'User Detail',
      resource: 'Admin-User',
      action: 'read',
    },
  },
]
