import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 1000,
  // headers: { 'api-key': '2e774c8e-3071-424e-a55c-05dea28fec7e' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
