export default [
  {
    path: '/miscellaneous/onestepup/tracking',
    name: 'Misc-OneStepUp-Active',
    component: () => import('@/views/Miscellaneous/OneStepUp/OneStepUp.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Active Containers', active: true },
      ],
      pageTitle: 'Active Containers',
      resource: 'Misc-OneStepUp',
      action: 'read',
    },
  },
  {
    path: '/miscellaneous/onestepup/tracking/archive',
    name: 'Misc-OneStepUp-Archive',
    component: () => import('@/views/Miscellaneous/OneStepUp/OneStepUpArchive.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Archived Containers', active: true },
      ],
      pageTitle: 'Archived Containers',
      resource: 'Misc-OneStepUp',
      action: 'read',
    },
  },
]
