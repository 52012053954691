import Vue from 'vue'

export default {}
Vue.mixin({
  methods: {
    LoadingStart() {
      // eslint-disable-next-line no-empty
      try { document.getElementById('loading').removeAttribute('style') } catch (e) {}

      return true
    },
    LoadingEnd() {
      // eslint-disable-next-line no-empty
      try { document.getElementById('loading').setAttribute('style', 'display: none') } catch (e) {}
      return true
    },
  },
})
