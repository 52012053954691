export default [
  {
    path: '/login',
    name: 'page-login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      pageTitle: 'Login',
      layout: 'full',
      resource: 'public',
      action: 'read',
      redirectIfLoggedIn: true,

    },
  },
  {
    path: '/register',
    name: 'page-register',
    component: () => import('@/views/login/Register.vue'),
    meta: {
      pageTitle: 'Register',
      layout: 'full',
      resource: 'public',
      action: 'read',
    },
  },
  {
    path: '/login/forgotpassword',
    name: 'page-forgot-password',
    component: () => import('@/views/login/ForgotPassword.vue'),
    meta: {
      pageTitle: 'Forgot Password',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/login/resetpassword/:resetId/:email',
    name: 'page-reset-password',
    component: () => import('@/views/login/ResetPassword.vue'),
    meta: {
      pageTitle: 'Reset Password',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/comingsoon',
    name: 'page-coming-soon',
    component: () => import('@/views/pages/ComingSoon.vue'),
    meta: {
      pageTitle: 'Coming Soon',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/error/notfound',
    name: 'error-error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      pageTitle: 'Not Found',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/error/error',
    name: 'error-error-500',
    component: () => import('@/views/pages/Error500.vue'),
    meta: {
      pageTitle: 'Error',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/error/notauthorized',
    name: 'error-not-authorized',
    component: () => import('@/views/pages/NotAuthorized.vue'),
    meta: {
      pageTitle: 'Not Authorized',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
  {
    path: '/maintenance',
    name: 'error-maintenance',
    component: () => import('@/views/pages/Maintenance.vue'),
    meta: {
      pageTitle: 'Maintenance',
      layout: 'full',
      resource: 'public',
      action: 'read',

    },
  },
]
