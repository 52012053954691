import Vue from 'vue'
import store from '@/store'

export default {}
Vue.mixin({
  methods: {
    GetUserRole() {
      return store.state.user.AppActiveUser.userRole
    },
    GetUserModule() {
      return store.state.user.AppActiveUser.Module
    },
    GetUserAllPermission() {
      return store.state.user.AppActiveUser.Permission
    },
    GetUserPermission(type) {
      return store.state.user.AppActiveUser.Permission[type]
    },
  },
})
