import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

// Bootstrap Components
import {
  BMedia, BMediaAside, BLink, BFormFile, BImg, BRow, BCol, BCard, BCardText, BFormInput, BFormInvalidFeedback, BButton, BFormGroup, BForm, BModal, BTabs, BTab, BFormTextarea, VBTooltip, BFormCheckbox, BFormDatepicker, BTable, BInputGroupAppend, BInputGroup, BButtonToolbar, BButtonGroup, BFormRadio, BCardTitle,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

import VJStree from 'vue-jstree'

// Vue Select Components
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

// Apex Charts
import VueApexCharts from 'vue-apexcharts'

// Vee Validate
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// AG Grid
import { AgGridVue } from 'ag-grid-vue'

import { LicenseManager } from 'ag-grid-enterprise'

import CKEditor from 'ckeditor4-vue'

LicenseManager.setLicenseKey('Production_License_Valid_Until__31_December_2300__MTA0NDUyNDE2MDAwMDA=d1a3d918aa159d68ab3e2b567ada2f46')

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(CKEditor)

// Bootstrap
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('app-timeline', AppTimeline)
Vue.component('app-timeline-item', AppTimelineItem)
Vue.component('app-collapse', AppCollapse)
Vue.component('app-collapse-item', AppCollapseItem)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-link', BLink)
Vue.component('b-media', BMedia)
Vue.component('b-media-aside', BMediaAside)
Vue.component('b-img', BImg)
Vue.component('b-form-file', BFormFile)
Vue.component('b-card', BCard)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-button', BButton)
Vue.component('b-button-toolbar', BButtonToolbar)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-modal', BModal)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-table', BTable)
// Vue Select
Vue.component('v-select', vSelect)
// Ag Gird vue
Vue.component('ag-grid-vue', AgGridVue)

// Vee Validate
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)

// Directive for tooltips and ripple effect
Vue.directive('b-tooltip', VBTooltip)
Vue.directive('ripple', Ripple)

// Vue Tree
Vue.component('v-jstree', VJStree)
