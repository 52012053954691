import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { canNavigate } from '@/libs/acl/routeProtection'
import admin from './routes/admin'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import booking from './routes/booking'
import abi from './routes/abi'
import user from './routes/user'
import misc from './routes/miscellaneous'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    ...admin,
    ...dashboard,
    ...pages,
    ...booking,
    ...abi,
    ...user,
    ...misc,
    {
      path: '*',
      redirect: 'error-error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!store.state.user.Loggedin) return next({ name: 'page-login' })

    // If logged in => not authorized
    return next({ name: 'error-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && store.state.user.Loggedin) {
    localStorage.clear()
    next()
  }
  return next()
})

export default router
