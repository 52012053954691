export default [
  {
    path: '/booking',
    name: 'Booking',
    component: () => import('@/views/Booking/Booking.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Booking', active: true },
      ],
      pageTitle: 'Booking List',
      resource: 'Booking',
      action: 'read',
    },
  },
  {
    path: '/booking/detail/:bookingId',
    name: 'Booking-Detail',
    component: () => import('@/views/Booking/BookingDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Booking List' },
        { text: 'Booking Detail', active: true },
      ],
      navActiveLink: 'Booking',
      pageTitle: 'Booking Detail',
      back: true,
      resource: 'Booking',
      action: 'read',
    },
  },
  {
    path: '/booking/carrier',
    name: 'Booking-Carrier',
    component: () => import('@/views/Booking/Carriers.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Carriers', active: true },
      ],
      pageTitle: 'Carriers',
      resource: 'Booking-Carrier',
      action: 'read',
    },
  },
  {
    path: '/booking/carrier/detail/:carrierId',
    name: 'Booking-CarrierDetail',
    component: () => import('@/views/Booking/CarrierDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Carriers', to: '/booking/carrier' },
        { text: 'Carrier Detail', active: true },
      ],
      navActiveLink: 'Booking-Carrier',
      pageTitle: 'Carrier Detail',
      resource: 'Booking-Carrier',
      action: 'read',
    },
  },
  //
  {
    path: '/booking/customer',
    name: 'Booking-Customer',
    component: () => import('@/views/Booking/Customers.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Customers', active: true },
      ],
      pageTitle: 'Customers',
      resource: 'Booking-Customer',
      action: 'read',
    },
  },
  {
    path: '/booking/customer/detail/:customerId',
    name: 'Booking-CustomerDetail',
    component: () => import('@/views/Booking/CustomerDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Customers', to: '/booking/customer' },
        { text: 'Customer Detail', active: true },
      ],
      navActiveLink: 'Booking-Customer',
      pageTitle: 'Customer Detail',
      resource: 'Booking-Customer',
      action: 'read',
    },
  },
  // Shipment
  {
    path: '/booking/shipment',
    name: 'Booking-Shipment',
    component: () => import('@/views/Booking/Shipment.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Shipments', active: true },
      ],
      pageTitle: 'Shipments',
      resource: 'Booking-Shipment',
      action: 'read',
    },
  },
  {
    path: '/booking/shipment/detail/:shipmentId',
    name: 'Booking-ShipmentDetail',
    component: () => import('@/views/Booking/ShipmentDetail.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Shipments' },
        { text: 'Shipment Detail', active: true },
      ],
      navActiveLink: 'Booking-Shipment',
      pageTitle: 'Shipment Detail',
      back: true,
      resource: 'Booking-Shipment',
      action: 'read',
    },
  },
  // Container Tracking
  {
    path: '/booking/containertracking',
    name: 'Booking-ContainerTracking',
    component: () => import('@/views/Booking/ContainerTracking.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Container Tracking', active: true },
      ],
      pageTitle: 'Container Tracking',
      resource: 'Booking-ContainerTracking',
      action: 'read',
    },
  },
  // Settings
  {
    path: '/booking/settings',
    name: 'Booking-Settings',
    component: () => import('@/views/Booking/Settings.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Settings', active: true },
      ],
      pageTitle: 'Settings',
      resource: 'Booking-Settings',
      action: 'read',
    },
  },
  // Archive
  {
    path: '/booking/archive/bookings',
    name: 'Booking-Archive',
    component: () => import('@/views/Booking/BookingArchive.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Booking Archive', active: true },
      ],
      pageTitle: 'Booking Archive',
      resource: 'Booking',
      action: 'read',
    },
  },
  {
    path: '/booking/archive/shipments',
    name: 'Booking-Shipments-Archive',
    component: () => import('@/views/Booking/ShipmentArchive.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Shipment Archive', active: true },
      ],
      pageTitle: 'Shipment Archive',
      resource: 'Booking-Shipment',
      action: 'read',
    },
  },
  // Reports
  {
    path: '/booking/reports/containerstatus',
    name: 'Booking-Reports-ContainerStatus',
    component: () => import('@/views/Booking/Report/ContainerStatus.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'Container Status', active: true },
      ],
      pageTitle: 'Container Status',
      resource: 'Booking-Reports-ContainerStatus',
      action: 'read',
    },
  },
  {
    path: '/booking/reports/dsr',
    name: 'Booking-Reports-DSR',
    component: () => import('@/views/Booking/Report/DSR.vue'),
    meta: {
      breadcrumb: [
        // { text: 'Home', to: '/dashboard' },
        { text: 'DSR', active: true },
      ],
      pageTitle: 'DSR',
      resource: 'Booking-Reports-DSR',
      action: 'read',
    },
  },
]
