import SecureLS from 'secure-ls'
import axios from '@axios'

const ls = new SecureLS({ isCompression: false, encodingType: 'des' })

const userDefaults = {
  Id: 1,
  displayName: '',
  photoURL: '',
  userRole: '',
  ClientId: '',
  UserName: '',
  ABIDbContext: '',
  ACASDbContext: '',
  AirAMSDbContext: '',
  OceanAMSDbContext: '',
  TruckRailAMSDbContext: '',
  BookingDbContext: '',
  Email: '',
  FirstName: '',
  LastName: '',
  PhoneNumber: '',
  EmailConfirmed: false,
  Module: [],
  Permission: [],
  Dashboard: [],
  ModuleSearchList: [],

}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
// eslint-disable-next-line camelcase
function is_touch_device() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = query => window.matchMedia(query).matches

  if (('ontouchstart' in window) || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: userDefaults,
    Loggedin: false,
    is_touch_device: is_touch_device(),
    status: '',
  },
  getters: {
    isLoggedIn: state => state.Loggedin,
    authStatus: state => state.status,
  },
  mutations: {
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
      const userInfo = JSON.parse(ls.get('userInfo')) || state.AppActiveUser

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          state.AppActiveUser[property] = payload[property]
          // Update key in localStorage
          userInfo[property] = payload[property]
        }
      }
      // Store data in localStorage
      ls.set('userInfo', JSON.stringify(userInfo))
    },
    // Login
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state) {
      state.Loggedin = true
      state.status = 'success'
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.Loggedin = false
    },
    updateuserstore(state, payload) {
      state.AppActiveUser = payload
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.get(`/identity/Autenticate/${window.btoa(user.storedemail)}/${window.btoa(user.storedpassword)}`)
          .then(resp => {
            const data = JSON.parse(window.atob(resp.data))
            const userdata = data.User
            const role = data.Roles
            const claims = data.Claims
            // store in local storage
            ls.set('userInfo', JSON.stringify(userdata))
            ls.set('roleInfo', JSON.stringify(role))
            ls.set('claimInfo', JSON.stringify(claims))
            commit('auth_success')

            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            ls.remove('vuex')
            ls.remove('userInfo')
            ls.remove('roleInfo')
            ls.remove('claimInfo')
            reject(err)
          })
      })
    },
    // Register

    // logout
    logout({ commit }) {
      return new Promise(resolve => {
        commit('logout')
        ls.remove('vuex')
        ls.remove('userInfo')
        ls.remove('roleInfo')
        ls.remove('claimInfo')
        resolve()
      })
    },
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },

    // /////////////////////////////////////////////
    // Login Actions
    // /////////////////////////////////////////////
    // Refresh user after user login
    RefreshUser({ commit }) {
      // debug
      // console.info('role changed ' + payload.userRole)
      // Make API call to server for changing role

      // Change userInfo in localStorage and store
      // console.info(CurrentUser)
      // eslint-disable-next-line no-shadow
      let userDefaults = {
        Id: 1,
        displayName: '',
        photoURL: '',
        userRole: '',
        ClientId: '',
        UserName: '',
        ABIDbContext: '',
        ACASDbContext: '',
        AirAMSDbContext: '',
        OceanAMSDbContext: '',
        TruckRailAMSDbContext: '',
        BookingDbContext: '',
        Email: '',
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        EmailConfirmed: false,
        Module: [],
        Permission: [],
        Dashboard: [],
        ModuleSearchList: [],
      }

      try {
        const CurrentUser = JSON.parse(ls.get('userInfo'))
        const userRole = JSON.parse(ls.get('roleInfo'))
        const userClaims = JSON.parse(ls.get('claimInfo'))
        let module = []
        let dashboard = []
        let permission = []
        let moduleserachlist = []
        // get moudle serach list
        try {
          moduleserachlist = JSON.parse(userClaims.filter(el => el.Type === 'ModuleSearchList')[0].Value)
        } catch (e) {
          moduleserachlist = []
        }
        // Get permission
        try {
          permission = JSON.parse(userClaims.filter(el => el.Type === 'Permission')[0].Value)
        } catch (e) {
          permission = []
        }
        // Get modules
        try {
          // console.log(userClaims)
          module = JSON.parse(userClaims.filter(el => el.Type === 'Module')[0].Value)// userClaims.filter(el => el.Type === 'Module')[0].Value
          // console.log(module)
        } catch (e) {
          module = []
        }
        // Get dashboard
        try {
          dashboard = JSON.parse(userClaims.filter(el => el.Type === 'Dashboard')[0].Value)
        } catch (e) {
          dashboard = []
        }
        if (CurrentUser && userRole[0]) {
          userDefaults = {
            Id: CurrentUser.Id,
            displayName: CurrentUser.Name,
            photoURL: CurrentUser.UserPicture,
            userRole: userRole[0],
            ClientId: CurrentUser.ClientId,
            UserName: CurrentUser.UserName,
            ABIDbContext: CurrentUser.ABIDbContext,
            ACASDbContext: CurrentUser.ACASDbContext,
            AirAMSDbContext: CurrentUser.AirAMSDbContext,
            OceanAMSDbContext: CurrentUser.OceanAMSDbContext,
            TruckRailAMSDbContext: CurrentUser.TruckRailAMSDbContext,
            BookingDbContext: CurrentUser.BookingDbContext,
            Email: CurrentUser.Email,
            FirstName: CurrentUser.FirstName,
            LastName: CurrentUser.LastName,
            PhoneNumber: CurrentUser.PhoneNumber,
            EmailConfirmed: CurrentUser.EmailConfirmed,
            Module: module,
            Permission: permission,
            Dashboard: dashboard,
            ModuleSearchList: moduleserachlist,
          }
        }
        try {
          commit('updateuserstore', userDefaults)
        } catch (e) {
          // console.info(e.message)
        }
      // eslint-disable-next-line no-empty
      } catch (e) { }
    },
  },
}
