export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard/Home.vue'),
    meta: {
      pageTitle: 'Dashboard',
      resource: 'dashboard',
      action: 'read',
    },
  },
]
