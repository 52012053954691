import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'

const ls = new SecureLS({ isCompression: false, encodingType: 'des' })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
  },
  plugins: [
    createPersistedState({
      paths: ['app', 'appConfig', 'verticalMenu', 'user'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  strict: process.env.DEV,
})
